import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Page not found component. */
@Component({
	selector: 'ygmc-page-not-found',
	styleUrls: ['page-not-found.component.css'],
	templateUrl: 'page-not-found.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class PageNotFoundComponent {}
